.aboutNav {
	margin: 81px 0;
}
.main {
	display: flex;
	align-items: flex-start;
	gap: 100px;
}
.info {
	display: flex;
	flex-direction: column;
	gap: 51px;
}
.btns {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.title {
	font-size: 26px;
	color: #393184;
	font-weight: 600;
}
@media (max-width: 920px) {
	.aboutNav {
		margin: 81px 0;
	}
	.main {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 100px;
	}
	.info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		gap: 51px;
	}
	.btns {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
	}
	.title {
		text-align: center;
	}
}
@media (max-width: 500px) {
	.aboutNav {
		margin: 40px 0;
	}
	.main {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 40px;
	}
	.info {
		gap: 30px;
	}
	.title {
		text-align: center;
	}
}
