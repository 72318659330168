.date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Raleway";
}
.btn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.block{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 450px;
}
.img{
    height: 200px;
    object-fit: cover;
}
.info{
    height: 247px;
}
.texts{
    padding: 0 15px;
}