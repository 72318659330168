.itemText {
    font-style: Mixed;
    vertical-align: center;
    font-weight: 400px;
    font-size: 19px;
    line-height: 28px;
}
.itemText span {
    color: #393184;
    font-weight: 600;
    font-size: 20px;
    line-height: 23.48px;
}

.content{
    padding: 80px 0;
    display: grid;
    grid-template-columns: 285px 1fr;
    gap: 60px;
}
.left, .right {
    width: 100%;
}


@media (max-width: 500px) {
    .content{
        grid-template-columns: 1fr;
    }
}

@media (max-width:920px){
    .itemText {
        padding: 20px;
    }
    .content {
        grid-template-columns: 1fr;
    }
}