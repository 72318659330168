.banner6 {
	position: relative;
}
.img {
	width: 100%;
}
.icon {
	position: absolute;
	width: 128px !important;
	height: 42px !important;
	top: 13px;
	left: 7px;
}
.info {
	position: absolute;
	width: 90%;
	top: 5%;
	left: 5%;
	display: flex;
	flex-direction: column;
	gap: 50px;
}
.info h2 {
	width: 70%;
	height: 64px;
	background-color: #ef7f1a;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 40px;
	color: white;
	margin-left: 16%;
}
.infoBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 287px;
}
.infoBlock ul li {
	font-size: 35px;
	font-weight: 700;
	color: white;
	letter-spacing: 1px;
}
.listGroup1 li::first-letter {
	color: #EF7F1A;
}
.listGroup2 li::first-letter {
	color: #393184;
}
.infoBlock ul {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 33px;
}
.arrow {
	width: 50% !important;
}
.arrowBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0.4;
}
@media (max-width: 1200px) {
	.info {
		width: 90%;
		top: 5%;
		left: 5%;
		gap: 0;
	}
	.info h2 {
		width: 70%;
		height: 64px;
		font-size: 40px;
	}
	.infoBlock {
		height: auto;
	}
	.infoBlock ul li {
		font-size: 35px;
	}
	.infoBlock ul {
		gap: 25px;
	}
	.arrow {
		width: 60% !important;
	}
}
@media (max-width: 1000px) {
	.info {
		width: 90%;
		top: 3%;
		left: 5%;
		gap: 0;
	}
	.info h2 {
		width: 70%;
		height: 64px;
		font-size: 35px;
	}
	.infoBlock {
		position: relative;
		left: 30px;
	}
	.infoBlock ul li {
		font-size: 28px;
	}
	.infoBlock ul {
		gap: 20px;
	}
}
@media (max-width: 900px) {
	.info {
		width: 90%;
		top: 3%;
		left: 5%;
		gap: 0;
	}
	.icon {
		width: 100px !important;
		height: 30px !important;
	}
	.info h2 {
		width: 67%;
		height: 54px;
		font-size: 28px;
	}
	.infoBlock {
		position: relative;
		left: 0;
	}
	.infoBlock ul li {
		font-size: 22px;
	}
	.infoBlock ul {
		gap: 20px;
	}
}
@media (max-width: 720px) {
	.info {
		width: 90%;
		top: 3%;
		left: 5%;
		gap: 0;
	}
	.icon {
		width: 60px !important;
		height: 25px !important;
	}
	.info h2 {
		width: 67%;
		height: 54px;
		font-size: 20px;
	}
	.infoBlock {
		position: relative;
		left: 0;
	}
	.infoBlock ul li {
		font-size: 16px;
	}
	.infoBlock ul {
		gap: 15px;
	}
}
@media (max-width: 600px) {
	.info {
		width: 90%;
		top: 3%;
		left: 5%;
		gap: 0;
	}
	.icon {
		width: 60px !important;
		height: 25px !important;
	}
	.info h2 {
		width: 67%;
		height: 40px;
		font-size: 15px;
	}
	.infoBlock {
		position: relative;
		left: 0;
	}
	.infoBlock ul li {
		font-size: 12px;
	}
	.infoBlock ul {
		gap: 15px;
	}
}
@media (max-width: 500px) {
	.icon {
		width: 31px !important;
		height: 11px !important;
	}
	.info {
		position: absolute;
		width: 90%;
		top: 5%;
		left: 5%;
		gap: 10px;
	}
	.info h2 {
		width: 60%;
		height: 20px;
		font-size: 12px;
		margin-left: 16%;
	}
	.infoBlock {
		display: flex;
		height: 78px;
		position: relative;
		right: 10px;
	}
	.infoBlock ul li {
		font-size: 10px;
	}
	.infoBlock ul {
		gap: 10px;
	}
	.arrowBlock {
		flex: 0.4;
	}
}
@media (max-width: 360px) {
	.info {
		position: absolute;
		width: 90%;
		top: 5%;
		left: 8%;
		gap: 2px;
	}
	.info h2 {
		width: 60%;
		height: 20px;
		font-size: 11px;
		margin-left: 16%;
	}
	.infoBlock {
		display: flex;
		height: 78px;
		position: relative;
		right: 10px;
	}
	.infoBlock ul li {
		font-size: 8px;
	}
	.infoBlock ul {
		gap: 8px;
	}
	.arrowBlock {
		flex: 0.4;
	}
}
