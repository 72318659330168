.my-slider .swiper {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.my-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  top: 100%;
  margin-top: 25px;
  position: static;
}

.swiper-pagination-bullet-active {
  background-color: #666666 !important;
  z-index: 5 !important;
}

.swiper-button-prev:active,
.swiper-button-next:active {
  background-color: #666666;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #EEE;
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
  font-size: 10px;
  color: #000 !important;
  opacity: .6;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 18px !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  display: none !important;
}

@media (max-width: 500px) {

  .swiper-button-prev,
  .swiper-button-next {
    width: 24px !important;
    height: 24px !important;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 7px !important;
  }
}