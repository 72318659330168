.locationInfo {
  display: flex;
  flex-direction: column;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 26px;
  line-height: 31px;
  font-weight: 600;
  color: #393184;
  margin-bottom: 30px;
}

.text {
  color: #393184;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  width: 203px;
  height: 31px;
}

.itemText {
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  width: 400px;
  height: 112px;
}

.map {
  width: 400px;
  height: 309px;
}

.map iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 500px) {
  .locationInfo {
    margin-top: 40px;
  }

  .itemText {
    text-align: left;
    width: 350px;
    font-size: 16px;
  }
}