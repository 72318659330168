.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentImg {
  width: 100px;
}

.contentImg img {
  width: 100%;
  height: 100%;
}

.contentText {
  line-height: 28px;
  font-size: 20px;
  color: #424242;
  max-width: 690px;
  width: 100%;
  margin-left: 30px;
}

.contentText p {
  padding: 0;
  margin: 0;
}

.contentIcon {
    width: 60px;
    height: 60px;
    justify-self: end;
    cursor: pointer;
}

.contentIcon:active {
    opacity: 0.5;
}

.contentIcon img {
  width: 100%;
  height: 100%;
}

@media (max-width: 500px) {
  .content {
    flex-direction: column;
    gap: 20px;
  }
  .contentText {
    width: 100%;
  }
  .contentIcon {
    position: absolute;
    top: 10%;
    width: 32px;
    height: 32px;
    right: 10%;
  }
}
@media (max-width: 920px) {
  .content {
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  .contentText {
    width: 100%;
  }
  .contentIcon {
    position: absolute;
    top: 10%;
    width: 32px;
    height: 32px;
    right: 10%;
  }
}
