.animationText {
  width: 771px;
  height: 31px;
  overflow: hidden;
}
.animationText .swiper-wrapper {
  height: 31px !important;
}
.itemText {
  height: 40px;
  top: 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
}

@media (max-width: 920px) {
  .animationText {
    width: 100%;
    height: 44px;
    text-align: center;
    display: grid;
    margin: 10px 0;
  }
  .itemText {
    font-size: 16px;
    line-height: 18px;
  }
}
