.company {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 70px 0;
}
.blockImg {
	max-width: 350px;
	width: 100%;
}
.blockImg img {
	width: 100%;
	height: 100%;
}
.info {
	margin-top: 40px;
}
@media (max-width: 920px) {
	.company {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 70px 0;
	}
	.blockImg img {
		width: 70%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
	}
	.info {
		margin-top: 40px;
	}
}
@media (max-width: 500px) {
	.company {
		margin: 30px 0;
	}

	.info {
		margin-top: 30px;
	}
}
