.block{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 60px;
}
.block a{
	color: white;
}
.title{
	color: #EF7F1A;
	font-size: 26px;
	font-weight: 600;
}
.title span{
	color: #393184;
}
.text{
	font-size: 20px;
	line-height: 1.4;
}

.link{
	max-width: 284px;
	height: 48px;
	background-color: #393184;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
}
@media (max-width:500px){
	.block{
		gap: 30px;
	}
	.title{
		color: #EF7F1A;
		text-align: center;
		font-size: 26px;
		font-weight: 600;
	}
	.title span{
		color: #393184;
	}
	.text{
		font-size: 20px;
		line-height: 1.4;
	}
	
	.link{
		padding: 10px 20px;
	}
}