.form {
	display: flex;
	align-items: center;
}

.label {
	position: relative;
}

.input {
	width: 500px;
	height: 50px;
	border-radius: 12px;
	background-color: #f0efff;
	border: none;
	font-size: 20px;
	padding: 10px;
	outline: none;
	color: #727A7E;
}

.input::placeholder {
	font-size: 20px;
	padding: 20px 0 0 10px;
	color: #727A7E;
}

.icon {
	position: absolute;
	right: 10px;
	top: 13px;
	color: gray;
}

.select {
	width: 100%;
	height: 50px;
	border-radius: 12px !important;
	background-color: #f0efff;
	padding: 10px;
	font-size: 20px;
	color: gray;
	outline: none !important;
}

.projectSearch {
	display: flex;
	gap: 40px;
	flex-wrap: wrap;
}
.projectSearch span {
	color: #727A7E;
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 23px;
}

@media (max-width: 920px) {
	.input {
		width: 300px;
		height: 50px;
		font-size: 16px;
	}

	.input::placeholder {
		font-size: 16px;
	}

	.select {
		width: 100px;
		height: 50px;
		font-size: 16px;
	}

	.option {
		font-size: 16px;
	}
}
@media (max-width: 768px) {
	.projectSearch {
		flex-direction: column;
		gap: 8px;
		flex-wrap: nowrap;
		margin-bottom: 0;
	}
}
@media (max-width: 500px) {

	.label {
		width: 100%;
		margin: auto;
	}

	.input {
		width: 100%;
		height: 50px;
		font-size: 16px;
	}

	.input::placeholder {
		font-size: 16px;
		padding: 20px 0 0 5px;
	}

	.select {
		margin-top: 20px;
	}

	.option {
		font-size: 14px;
	}
}

@media (max-width: 350px) {
	.input {
		height: 45px;
		font-size: 16px;
	}

	.select {
		margin-top: 15px;
		font-size: 16px;
	}

	.option {
		font-size: 12px;
	}
}