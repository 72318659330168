.main {
	display: flex;
	flex-direction: column;
	margin: 90px 0 50px;
}
.profession {
	display: flex;
}
.right {
	display: grid;
	grid-template-columns: 386px 1fr;
	gap: 50px;
}

.info {
	flex: 2;
}
.info p {
	font-size: 20px;
	margin: 60px 0 40px;
}

.item h4 {
	color: #393184;
	font-size: 20px;
	font-weight: 500;
	margin: 40px 0 36px;
}
.list li {
	font-size: 20px;
	font-weight: 400;
	display: flex;
	line-height: 1.3;
}
.list li span {
	color: #ef7f1a;
}
.list {
	display: flex;
	flex-direction: column;
	align-items: left;
	gap: 15px;
}
@media (max-width: 920px) {
	.main {
		display: flex;
		flex-direction: column;
		gap: 30px;
		margin: 90px 0 50px;
	}
	.profession {
		display: flex;
	}
	.img{
		flex: 2;
	}
	.img {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.info p {
		line-height: 1.4;
	}
}
@media (max-width: 500px) {
	.main {
		display: flex;
		flex-direction: column;
		margin: 90px 0 50px;
	}
	.profession {
		display: flex;
		width: 100%;
		margin: auto;
		justify-content: center;
		margin-bottom: 30px;
	}
	.right {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.img {
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.info p {
		font-size: 20px;
		margin: 60px 0 40px;
	}
}
@media (max-width: 350px) {
	.main {
		margin: 70px 0 50px;
	}
	.info p {
		font-size: 16px;
		margin: 60px 0 40px;
	}
	.item h4 {
		font-size: 16px;
	}
	.list li {
		font-size: 16px;
	}
}
