.profession {
	width: 85%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: end;
}
.block {
	position: relative;
	margin-bottom: 40px;
	width: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.img {
	width: 190px;
	position: absolute;
	left: 9%;
}
.name {
	font-size: 32px;
	font-weight: 500;
	position: absolute;
	margin: 0;
	top: 10%;
	left: 15%;
	width: 100%;
}
.text {
	font-size: 24px;
	font-weight: 500;
	color: #393184;
	text-align: center;
	position: relative;
	right: 50px;
	width: 500px;
	margin: 0;
}
@media (max-width: 920px) {
	.profession {
		margin-bottom: 10px;
		gap: 20px;
	}
	.text {
		right: 70px;
	}
}
@media (max-width: 700px) {
	.profession {
		width: 85%;
	}
	.block {
		width: 480px;
	}
	.img {
		left: 15%;
	}
	.name {
		margin: 0;
		top: 10%;
		left: 20%;
	}
	.text {
		right: 20px;
		width: 480px;
	}
}
@media (max-width: 600px) {
	.profession {
		width: 85%;
	}
	.block {
		width: 480px;
	}
	.img {
		left: 20%;
	}
	.name {
		margin: 0;
		top: 10%;
		left: 28%;
	}
	.text {
		left: 20px;
		width: 480px;
	}
}
@media (max-width: 500px) {
	.profession {
		width: 360px;
	}
	.block {
		position: relative;
		margin-bottom: 40px;
		width: 90%;
		display: flex;
		justify-content: center;
	}
	.img {
		width: 60%;
		left: 0%;
	}
	.name {
		font-size: 25px;
		font-weight: 500;
		position: absolute;
		top: 5px;
		left: 10%;
	}
	.text {
		font-size: 18px;
		left: 40px;
	}
}

@media (max-width: 350px) {
	.profession {
		width: 360px;
	}
	.block {
		position: relative;
		margin-bottom: 20px;
		width: 90%;
		display: flex;
		justify-content: center;
	}
	.img {
		width: 60%;
		left: 10%;
	}
	.name {
		font-size: 20px;
		font-weight: 500;
		position: absolute;
		top: 5px;
		left: 20%;
	}
	.text {
		font-size: 14px;
		left: 60px;
	}
}
