@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}
