.banner5 {
	position: relative;
}
.img {
	width: 100%;
}
.icon {
	position: absolute;
	top: 13px;
	left: 7px;
	max-width: 128px;
	max-height: 42px;
}
.block {
	position: absolute;
	width: 527px;
	top: 30%;
	left: 10%;
}
.block h2 {
	font-size: 36px;
	color: white;
	text-align: left;
	line-height: 38px;
}
.block p {
	font-size: 24px;
	line-height: 1.4;
	font-weight: 400;
	color: white;
	text-align: left;
}
.block button {
	margin-top: 30px;
	float: left;
	padding: 10px 10px;
}
@media (max-width: 920px) {
	.icon {
		max-width: 100px;
		max-height: 35px;
	}
	.block {
		width: 527px;
		top: 20%;
		left: 10%;
	}
	.block h2 {
		font-size: 30px;
	}
	.block p {
		font-size: 18px;
	}
	.block button {
		margin-top: 20px;
	}
}
@media (max-width: 650px) {
	.icon {
		max-width: 70px;
		max-height: 30px;
	}
	.block {
		width: 527px;
		top: 20%;
		left: 10%;
	}
	.block h2 {
		font-size: 25px;
	}
	.block p {
		font-size: 16px;
	}
	.block button {
		margin-top: 20px;
		font-size: 16px;
	}
}
@media (max-width: 500px) {
	.icon {
		top: 13px;
		left: 7px;
		max-width: 35px;
		max-height: 11px;
	}
	.block {
		position: absolute;
		width: 527px;
		top: 10%;
		left: 12%;
	}
	.block h2 {
		font-size: 20px;
		font-weight: 500;
		margin: 10px 0 10px 0;
	}
	.block p {
		font-size: 14px;
		line-height: 1.3;
		margin: 0 0 5px 0;
		font-weight: 300;
		width: 55%;
	}
	.block Button {
		padding: 5px 10px;
		font-size: 14px;
		width: 230px;
		height: 39px;
		margin-top: 10px;
	}
}
@media (max-width: 400px) {
	.icon {
		top: 13px;
		left: 7px;
		max-width: 35px;
		max-height: 11px;
	}
	.block {
		width: 400px;
		top: 10%;
		left: 12%;
	}
	.block h2 {
		font-size: 15px;
		font-weight: 500;
		margin: 10px 0 10px 0;
	}
	.block p {
		font-size: 8px;
		line-height: 1.3;
		margin: 0 0 10px 0;
		font-weight: 300;
		width: 50%;
	}
	.block Button {
		padding: 5px 10px;
		font-size: 10px;
		width: 210px;
		height: 30px;
		margin-top: 5px;
	}
}
@media (max-width: 320px) {
	.icon {
		top: 10px;
		left: 5px;
		max-width: 30px;
		max-height: 9px;
	}
	.block h2 {
		font-size: 13px;
		margin: 10px 0 5px 0;
	}
	.block p {
		font-size: 9px;
		margin: 0 0 10px 0;
	}
	.block Button {
		font-size: 8px;
		width: 190px;
		height: 26px;
		margin-top: 0;
	}
}
