.block {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 80px;
  padding-bottom: 110px;
}

@media (max-width: 920px) {
  .block {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 50px;
  }
}