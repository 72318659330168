.details {
	display: flex;
	flex-direction: column;
	margin: 70px 0 50px 0;
	gap: 20px;
}
.icon {
	font-size: 35px;
}
.back {
	z-index: 1;
}
.date {
	text-align: right;
	margin: 10px 0 0 0;
}
.imgs {
	margin: 30px 0;
}
.title {
	font-size: 30px;
	color: #3c0f78;
	text-align: center;
	line-height: 1.3;
	font-weight: 700;
	margin: 0;
}
.block {
	display: flex;
	flex-direction: column;
}
.block p,
h4,
li {
	font-size: 24px;
	line-height: 1.2;
	margin: 0;
	font-weight: 400;
}
.link {
	color: black;
	font-size: 24px;
	margin: 5px 0;
}
.lang {
	margin-top: 30px;
}
.info {
	margin-bottom: 30px;
}
.block p {
	margin-bottom: 30px;
}
.list {
	margin: 0 0 30px 0;
}
.img {
	width: 80%;
	margin: 110px auto;
}
@media (max-width: 920px) {
	.details {
		margin: 70px 0 50px 0;
		gap: 20px;
	}
	.icon {
		font-size: 30px;
	}

	.imgs {
		margin: 80px 0;
	}
	.title {
		font-size: 26px;
		text-align: left;
	}
	.block p,
	h4,
	li {
		font-size: 20px;
	}
	.link {
		font-size: 20px;
		overflow-wrap: break-word;
	}
}
@media (max-width: 500px) {
	.details {
		margin: 20px 0 20px 0;
		gap: 10px;
	}
	.icon {
		font-size: 20px;
	}
	.btn {
		width: 35px;
		height: 24px;
	}
	.date {
		font-size: 24px;
		margin: 10px 0 10px 0;
	}
	.title {
		font-size: 26px;
		text-align: left;
	}
	.block {
		width: 100%;
	}
	.block p,
	h4,
	li {
		font-size: 20px;
	}
	.img {
		width: 100%;
		margin: 120px auto;
	}
	.link {
		font-size: 20px;
		margin: 5px 0;
		max-width: 490px;
		overflow-wrap: break-word;
	}
}
@media (max-width: 350px) {
	.details {
		margin: 20px 0 20px 0;
		gap: 10px;
	}
	.icon {
		font-size: 20px;
	}
	.date {
		font-size: 20px;
		margin: 10px 0 10px 0;
	}
	.title {
		font-size: 20px;
		text-align: left;
	}
	.block {
		width: 100%;
	}
	.block p,
	h4,
	li {
		font-size: 16px;
	}
	.img {
		width: 100%;
		margin: 70px auto;
	}
	.link {
		font-size: 16px;
	}
}
