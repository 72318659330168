.aboutSlider {
	position: relative;
	height: 400px;
	width: 387px;
	overflow: hidden;
	border-radius: 10px;
	margin-left: 45px;
}

.img {
	height: 400px;
	width: 387px;
}
.img img {
	width: 100%;
	height: 100%;
}
.textInfo {
	z-index: 11;
	position: absolute;
	bottom: 0;
	height: 80px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(5px);
	border-radius: 10px;
}
.textInfo p {
	font-size: 18px;
	color: white;
	text-align: center;
}
@media (max-width: 920px) {
	.aboutSlider {
		margin-left: 0;
	}
}
