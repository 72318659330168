.banner7 {
    width: 100%;
    max-height: 628px;
    overflow: hidden;
    height: 100%;
}
.banner7 img {
    width: 100%;
    object-fit: cover;
}
