.block {
	display: flex;
	flex-direction: column;
	margin-bottom: 200px;
}
.info {
	display: flex;
	justify-content: center;
	gap: 40px;
	flex-wrap: wrap;
}
.filterNews {
	margin-top: 10px;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	gap: 20px;
}
.filterNews h3 {
	color: gray;
}
.select {
	width: 100%;
	height: 50px;
	border-radius: 12px !important;
	background-color: #f0efff;
	padding: 10px;
	font-size: 20px;
	color: gray;
	outline: none !important;
}
@media (max-width: 920px) {
	.block {
		margin: auto;
		display: flex;
		flex-direction: column;
	}
	.info {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 20px;
	}
}
@media (max-width: 880px) {
	.info {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 20px;
	}
}
@media (max-width: 500px) {
	.block {
		margin: 30px 0;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 30px;
	}
}
