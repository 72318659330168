.menuBurger {
	position: absolute;
	background-color: #393184;
	width: 100%;
}

.listGroup {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px 15px;
	width: 100%;
}

.listGroup:last-child {
	display: flex;
	/* justify-content: center; */
	align-items: center;
}

.listItem {
	color: #fff !important;
	font-size: 24px;
	line-height: 28px;
	border-bottom: 1px solid #fff;
	cursor: pointer;
	width: 100%;
	padding-bottom: 8px;
	padding-top: 15px;
}

.aboutItem {
	display: flex;
	flex-direction: column;
}

.aboutItem a {
	color: #fff !important;
	margin-left: 15px;
	border-bottom: 1px solid #fff;
	cursor: pointer;
	padding-bottom: 8px;
	padding-top: 15px;
	font-size: 16px;
}

.aboutItem a:nth-child(6) {
	border-bottom: none;
}

.language {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	width: 221px;
	height: 55px;
	background-color: #fff;
	margin-top: 30px;
}

.language button {
	border: none;
	background-color: #fff;
	font-size: 20px;
	font-weight: 500;
	color: black;
}

.ru,
.en {
	cursor: pointer;
}

.color {
	color: #685bf1 !important;
}

@media (max-width: 920px) {
	.menuBurger {
		z-index: 10;
		top: 60%;
	}

	.listGroup a {
		text-decoration: none;
	}
}

@media (max-width: 500px) {
	.menuBurger {
		top: 56%;
	}
}