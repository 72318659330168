.banner1 {
	position: relative;
}
.block {
	position: absolute;
	top: 35%;
	left: -200%;
	width: 455px;
	height: 162px;
	border: 8px solid #ef7f1a;
	border-radius: 30px;
	background-color: rgb(24, 20, 36, 0.7);
	display: flex;
	align-items: center;
	transition: 1.3s;
	justify-content: center;
}

.animation {
	position: absolute;
	top: 35%;
	left: 3%;
	width: 455px;
	transition: 1.3s;
	transform: translateX(3%);
	height: 162px;
	border: 8px solid #ef7f1a;
	border-radius: 30px;
	background-color: rgb(24, 20, 36, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon {
	position: absolute;
	right: 26px;
	top: 27px;
	max-width: 33px;
	max-height: 30px;
}
.text {
	font-size: 32px;
	color: white;
	width: 90%;
	line-height: 1.3;
	text-align: left;
}
@media (max-width: 920px) {
	.block {
		width: 400px;
		height: 130px;
		border: 5px solid #ef7f1a;
	}
	.icon {
		right: 26px;
		top: 27px;
		max-width: 27px;
		max-height: 24px;
	}
	.animation {
		top: 35%;
		left: 3%;
		width: 400px;
		border: 5px solid #ef7f1a;
		height: 130px;
	}

	.text {
		font-size: 20px;
		width: 80%;
		text-align: left;
	}
}
@media (max-width: 650px) {
	.block {
		width: 300px;
		height: 110px;
		border: 3px solid #ef7f1a;
	}
	.icon {
		right: 26px;
		top: 27px;
		max-width: 22px;
		max-height: 19px;
	}
	.animation {
		top: 35%;
		left: 3%;
		width: 300px;
		height: 110px;
		border: 3px solid #ef7f1a;
	}

	.text {
		font-size: 15px;
		width: 80%;
		text-align: left;
	}
}
@media (max-width: 500px) {
	.block {
		max-width: 185px;
		max-height: 70px;
	}

	.animation {
		left: 0%;
		max-width: 185px;
		height: 70px;
		border: 2px solid #ef7f1a;
	}
	.icon {
		right: 15px;
		top: 14px;
		max-width: 20px;
		max-height: 18px;
	}
	.text {
		font-size: 14px;
		font-weight: 500;
		width: 90%;
		line-height: 1.3;
		text-align: left;
	}
}
@media (max-width: 300px) {
	.block {
		max-width: 155px;
		max-height: 55px;
	}

	.animation {
		max-width: 155px;
		height: 55px;
	}
	.icon {
		top: 10px;
		max-width: 15px;
		max-height: 14px;
	}
	.text {
		font-size: 10px;
		width: 80%;
	}
}
